import axios from "axios";
import {LOGGER, SERVER_ADDRESS} from "@/config";

const auth = {
    username: 'frontend',
    password: 'brt1fod5s12XFrglzqNfdTxYw0PDepXCfDB2U8AziSSt6off3JHym2bWBgRuYi42'
};

export function axiosGet(url) {
    /**
     * @param url: part-url, Server is added automatically
     */
    return axios.get(SERVER_ADDRESS + url, {
        auth: auth
    });
}

export function axiosPost(url, data) {
    /**
     * @param url: part-url, Server is added automatically
     * @param data: Data
     */
    return axios.post(SERVER_ADDRESS + url, data, {
        auth: auth
    });
}

export function axiosDelete(url) {
    /**
     * @param url: part-url, Server is added automatically
     * @param data: Data
     */
    return axios.delete(SERVER_ADDRESS + url, {
        auth: auth
    });
}

export function axiosPatch(url, data) {
    /**
     * @param url: part-url, Server is added automatically
     * @param data: Data
     */
    return axios.patch(SERVER_ADDRESS + url, data, {
        auth: auth
    });
}

export function axiosPostLog(url, data) {
    /**
     * @param url: part-url, Server is added automatically
     * @param data: Data
     */
    return axios.post(LOGGER + url, data, {
        auth: auth
    });
}

export function axiosPatchLog(url, data) {
    /**
     * @param url: part-url, Server is added automatically
     * @param data: Data
     */
    return axios.patch(LOGGER + url, data, {
        auth: auth
    });
}