import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store.js';
import VueCookie from 'vue-cookies'
import {axiosPostLog} from "@/utils/axiosWrapper";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Language_Container.vue'),
  },
  {
    path: '/r/:link',
    component: () => import('../views/Referral.vue'),
  },

  {
    path: '/:language',
    component: () => import('../views/Language_Container.vue'),
    children: [
      {
        path: '/',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'Landing',
        component: () => import('../views/Landing.vue')
      },
      {
        path: 'support/peer',
        name: 'Peer Support',
        component: () => import('../views/PeerSupport.vue')
      },
      // {
      //   path: 'support/peer',
      //   name: 'Peer Support',
      //   component: () => import('../views/PeerSupport.vue')
      // },
      {
        path: 'support/self',
        name: 'Self Support',
        component: () => import('../views/SelfSupport.vue')
      },
      {
        path: 'support/self/:kind',
        name: 'Self Support Content',
        component: () => import('../views/SelfSupportContent.vue')
      },
      {
        path: 'support/self/recommendation',
        name: 'Recommendation - Self Support',
        component: () => import('../views/Recommendation.vue')
      },
      {
        path: 'questions',
        name: 'Questions',
        component: () => import('../views/Questions.vue')
      },
      {
        path: 'AlternativeSupport',
        name: 'AlternativeSupport',
        component: () => import('../views/AlternativeSupport.vue')
      },
      {
        path: 'Donate',
        name: 'Donate',
        component: () => import('../views/Donate.vue')
      },
      {
        path: 'Signin',
        name: 'Signin',
        component: () => import('../views/Signin.vue')
      },
      {
        path: 'Signup',
        name: 'Signup',
        component: () => import('../views/Signup.vue')
      },
      // {
      //   path: 'Signup',
      //   name: 'Signup',
      //   component: () => import('../views/Signup.vue')
      // },
      {
        path: 'chat',
        name: 'Chat',
        component: () => import('../views/Chat.vue')
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: () => import('../views/Privacy.vue')
      },
      {
        path: 'legal',
        name: 'Legal',
        component: () => import('../views/Legal.vue')
      },
      {
        path: 'newsletter',
        name: 'Newsletter',
        component: () => import('../views/Newsletter.vue')
      },
      {
        path: 'article/:name',
        name: 'Article',
        component: () => import('../views/Article.vue')
      },
      {
        path: '*',
        redirect: 'home'
      },
    ]
  }
]

const protectedRoutes = [
    'Chat',
    'Dashboard'
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: 'reload',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch('fetchAccessToken');
  if (to.name !== 'Signin') {
    if (store.state.accessToken == null) {
      if(protectedRoutes.includes(to.name)) {
        console.warn("Tried to access protected area without being logged in. Routing to sign-in page.")
        next(`/${to.params.language}/Signin?to=${to.path}` );
      }
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  let uuid = VueCookie.get('uuid')
  if (uuid == null){
    uuid = uuidv4()
    VueCookie.set('uuid', uuid, "356d");
  }
  let data = {
    'path' : to.path,
    'uuid' : uuid,
    'vh': window.innerHeight,
    'vw': window.innerWidth,

  }
  VueCookie.get('uuid')

  if(window.location.hostname.startsWith('mindsupport') || window.location.hostname.startsWith('beta'))
    axiosPostLog("/log", {
      data
    })
        .catch(function (error) {
          console.warn(error);
        });
    next();
  });

// Beta protection
router.beforeEach((to, from, next) => {
  // check if beta cookie is set
    if (VueCookie.get('beta') === 'true') {
        next();
    }
    else if (window.location.hostname.startsWith('beta')) {
      //promp, ask for password, if correct, continue
        let password = prompt("Please enter password to continue", "");
         if (password === "pleaseDontLaughAtTheOldDesign:)") {
           // save cookie for 1 day
              VueCookie.set('beta', 'true', "1d");
            next();
        }
        else {
            alert("Incorrect password");
            next(false);
         }

    }

})

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default router
