<template>
  <div id="app">
    <!--    <dev-email />-->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
// import devEmail from "@/components/devEmail";
export default {
  components: {
    // devEmail
  },
  mounted() {},
};
</script>

<style>
:root {
  --h1: "amatic sc", sans-serif;
  --h2: "amatic sc", sans-serif;
  --h3: "Gaegu", cursive;
  --text: "DM Sans", sans-serif;
  --c1: #dfab79;
  --c2: #e0be9d;
  --c3: #51638B;
  --c4: #4F83AB;
  --c5: #6E502E;
  --articleh1: "Tauri", sans-serif;
  --articleText: "Taviraj", serif;
}

h1 {
  /*font-family: "Caveat Brush", cursive;*/
  font-family: "amatic sc", sans-serif;;
}
h2 {
  /*font-family: "Gayathri", sans-serif;*/
  font-family: "amatic sc", sans-serif;;
}
h3 {
  /*font-family: "Gaegu", cursive;*/
  font-family: "Gaegu", cursive;;
}
a:link {
  color: #005c53 !important;
}
a:visited {
  color: #005c53 !important;
}
#app {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.button {
  background: #111010;
  padding: 5px 10px 5px 10px;
  color: rgb(255, 255, 255);
}
.footer {
  position: relative;
}
</style>
