import Vue from 'vue'
import Vuex from 'vuex'
import router from './router';
import VueCookie from 'vue-cookies'
import {axiosPost} from "@/utils/axiosWrapper";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        accessToken: null,
        loggingIn: false,
        loginError: null
    },
    mutations: {
        loginStart: state => state.loggingIn = true,
        loginStop: (state, errorMessage) => {
            state.loggingIn = false;
            state.loginError = errorMessage;
        },
        updateAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
        },
        logout: (state) => {
            state.accessToken = null;
        }
    },
    actions: {
        doLogin({commit}, loginData) {
            commit('loginStart');
            // axios.post('api/login.php', {
            axiosPost('/users/validate', {
                email: loginData.email.toLowerCase(),
                password: loginData.password,
                answers: loginData.answers,
                aboutMe: loginData.aboutMe
            },)
                .then(response => {
                    if (response.data.result) {
                        VueCookie.set('accessToken', response.data.result);
                        commit('loginStop', null);
                        commit('updateAccessToken', response.data.result);


                        VueCookie.set('email', loginData.email, '2m');
                        if (loginData.rememberMeChecked) {
                            VueCookie.set('password', loginData.password, '1m');
                        } else {
                            VueCookie.remove('password');
                        }
                        if (loginData.route) {
                            router.push(loginData.route);
                        } else
                            router.push('dashboard');
                    } else {
                        if (response.data.error != null) commit('loginStop', response.data.error);
                        else commit('loginStop', "Undefined Error");
                        commit('updateAccessToken', null);
                    }
                })
                .catch(error => {
                    commit('loginStop', error.response.data.error);
                    commit('updateAccessToken', null);
                })
        },
        fetchAccessToken({commit}) {
            commit('updateAccessToken', VueCookie.get('accessToken'));
        },
        logout({commit}) {
            VueCookie.remove('accessToken');
            commit('logout');
            router.push('/');
        }
    }
})
