import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import VueCookies from 'vue-cookies'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/black-green-light.css'
import Carousel3d from 'vue-carousel-3d'

// eslint-disable-next-line no-unused-vars
const prod = process.env.NODE_ENV === "development"

const VueScrollTo = require('vue-scrollto');


Vue.use(new VueSocketIO({
  debug: prod,
  connection: SocketIO(config.SOCKET_ADDRESS),
}))
Vue.use(VueCookies)
Vue.use(VueMaterial)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(Carousel3d)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
