const dev = process.env.NODE_ENV === "development";
const dbOnline = true;

const SERVER_ADDRESS =
  dev && !dbOnline ? "http://127.0.0.1:3002" : "https://api.mindsupport.eu";
const SOCKET_ADDRESS =
  dev && !dbOnline ? "ws://localhost:3002" : "wss://api.mindsupport.eu";
// const SOCKET_ADDRESS = "ws://localhost:3002"
const LOGGER = "https://log.mindsupport.eu";

module.exports = {
  SERVER_ADDRESS,
  SOCKET_ADDRESS,
  LOGGER,
};
